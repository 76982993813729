<template>
  <section id="landing">
    <!-- 顶部广告 -->
    <AdComponent v-if="themeConfig.landAdPos.includes(0)" adType="detail_ad" :adPosition="0" />

    <div v-if="showAnimation" class="animation-container">
      <img src="@/assets/animate.gif" alt="Animation" class="animation">
    </div>

    <section v-if="hasNews" id="el" class="content">
      <h1 class="news-title">
        {{ newsObj?.title }}
      </h1>
      <div class="news-info-row">
        <div>
          {{ newsObj?.channel }}
        </div>
        <div>
          {{ newsObj?.publishedTime? $global.timeAgo($global.dateStringToTimestamp(newsObj?.publishedTime)):'recently' }}
        </div>
      </div>

      <img :src="newsObj?.poster" class="figure_poster" :alt="newsObj?.poster">

      <!-- 中间广告 -->
      <AdComponent v-if="themeConfig.landAdPos.includes(1)" adType="detail_ad" :adPosition="1" />

      <template v-if="newsObj?.content && newsObj?.content.length > 0 ">
        <section v-for="(context, i) in newsObj?.content" :key="i" class="content-row">
          <h2 v-if="context.subTitle" v-html="context.subTitle" />
          <img v-show="i > 0" v-if="context.img" v-lazy="context.img" class="figure"
            :alt="context.subTitle">
          <div v-html="context.content" />
        </section>
      </template>
    </section>

    <!-- 底部广告 -->
    <AdComponent v-if="themeConfig.landAdPos.includes(2)" adType="detail_ad" :adPosition="2" />

    <section v-show="!hasNews && requirDataAlready" class="no-data-row">
      <img src="@/assets/news/nodata.png" alt="">
      <div class="no-data">
        Uh-oh. What you were looking for got away from you
      </div>
    </section>

    <div v-show="!requirDataAlready" class="loading">
      <img src="@/assets/news/loading.png" alt="">
    </div>

    <!-- -----------------------这里是详情页和feed流的分割线----------------------- -->

    <Feed @feedClicked="handleFeedClicked" />
  </section>
</template>

<script>
import '@/css/module/landing/theme_1.scss';
import AdComponent from '@/pages/components/AdComponentDouble.vue'
import Feed from '@/pages/components/Feed.vue'

export default {
  components: { AdComponent, Feed },
  props: {
    newsId: {
      type: Number,
      required: true,
    },
    showDebug: {
      type: Boolean,
      default: false,
    },
    requirDataAlready: {
      type: Boolean,
      default () {
        return false
      }
    },
    hasNews: {
      type: Boolean,
      default () {
        return false
      }
    },
    newsObj: {
      require: true,
      type: Object,
      default () {
        return {}
      }
    },
    themeConfig: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      adsense: null,
      showAnimation: false,
      animationTriggered: false,
      animationTimeout: null
    };
  },
  computed: {
    deviceType () {
      return this.$store.state.deviceType;
    },
  },
  beforeMount () {
    this.adsense = this.$store.state.adsenseConfig;
  },
  mounted () {
    // setTimeout(() => {
    //   this.initializeEventListeners();
    // }, 500);
  },
  activated () {
    // setTimeout(() => {
    //   this.initializeEventListeners();
    // }, 500);
  },
  deactivated () {
    this.stopAndResetAnimation();
    // this.removeEventListeners();
  },
  beforeDestroy () {
    // this.removeEventListeners();
  },
  methods: {
    goback () {
      this.$router.go(-1);
    },
    handleFeedClicked (data) {
      this.$emit('childClicked', data);
    },
    initializeEventListeners () {
      this.animationTriggered = false; // 重置动画触发状态
      window.addEventListener('click', this.triggerAnimation);
      window.addEventListener('scroll', this.triggerAnimation);
    },
    removeEventListeners () {
      window.removeEventListener('click', this.triggerAnimation);
      window.removeEventListener('scroll', this.triggerAnimation);
    },
    triggerAnimation () {
      if (!this.animationTriggered) {
        this.showAnimation = true;
        this.animationTriggered = true;

        // 动画展示5秒后隐藏
        this.animationTimeout = setTimeout(() => {
          this.showAnimation = false;
        }, 5000);
      }
    },
    stopAndResetAnimation () {
      if (this.animationTimeout) {
        clearTimeout(this.animationTimeout);
        this.animationTimeout = null;
      }
      this.showAnimation = false;
      this.animationTriggered = false;
    }
  },
};
</script>
